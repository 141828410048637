import React from 'react';
import { GuideStep } from 'entities/Guide';
import { Language } from 'entities/Language';
import { Copy, Flex } from 'shared/_ui';
import { getImageByParams } from '../../../lib/utils/getImageByParams';
import { useTranslation } from 'react-i18next';
import { useSliderContext } from '../../../../../model';

export const LinuxAppInstruction: React.FC = () => {
  const { t, i18n } = useTranslation();

  const getImageByStep = getImageByParams(
    'linux',
    'app',
    i18n.language as Language
  );

  const { xrayConnectionLink } = useSliderContext();

  return (
    <Flex direction="column">
      <GuideStep
        number={1}
        image={getImageByStep(1)}
        title={t('guide.linux.app.step.1.title')}
        description={
          <ol>
            <li>{t('guide.linux.app.step.1.description.1')}</li>
            <li>{t('guide.linux.app.step.2.description.2')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={2}
        image={getImageByStep(2)}
        title={t('guide.linux.app.step.2.title')}
        description={
          <ol>
            <li>{t('guide.linux.app.step.2.description.1')}</li>
            <li>{t('guide.linux.app.step.2.description.2')}</li>
            <li>{t('guide.linux.app.step.2.description.3')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={3}
        image={getImageByStep(3)}
        title={t('guide.linux.app.step.3.title')}
        description={
          <ol>
            <li>{t('guide.linux.app.step.3.description.1')}</li>
            {xrayConnectionLink && (
                          <div style={{ marginTop: 12 }}>
                            <Copy
                              displayText={t('guide.app.linkKey')}
                              truncate
                              variant="filled"
                              text={xrayConnectionLink}
                            />
                          </div>
                        )}
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={4}
        image={getImageByStep(3)}
        title={t('guide.linux.app.step.4.title')}
        description={
          <ol>
            <li>{t('guide.linux.app.step.4.description.1')}</li>
            <li>{t('guide.linux.app.step.4.description.2')}</li>
            <li>{t('guide.linux.app.step.4.description.3')}</li>
          </ol>
        }
        final={false}
      />
      <GuideStep
        number={5}
        image={getImageByStep(4)}
        title={t('guide.linux.app.step.5.title')}
        description={
          <ol>
            <li>{t('guide.linux.app.step.5.description.1')}</li>
            <li>{t('guide.linux.app.step.5.description.2')}</li>
          </ol>
        }
        final={true}
      />
    </Flex>
  );
};