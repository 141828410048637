import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { userActions } from 'entities/User';
import { notify } from 'shared/lib/notification';
import { t } from '../../../../i18n';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const store = api.getState();
      const isAuth = Boolean(store.user?.authData);
      if (isAuth && action.payload.status === 403) {
        //notify.error(t('shared.error.timeSessionExpired'));
        api.dispatch(userActions.logout());
      }
      if (isAuth && action.payload.status === 500) {
        notify.error(t('shared.error.base'));
      }
    }

    return next(action);
  };
