import React from 'react';
import styles from './styles.module.scss';
import { Flex, Radio, Typography } from 'shared/_ui';
import { DEVICE_LABELS } from '../../const';
import { Device } from '../../types';
import { RadioGroupOption } from 'shared/_ui/Radio/Radio';
import classNames from 'classnames';

type DeviceSelectorProps = {
  value: Device;
  onChange: (value: Device) => void;
};

const DeviceSelector: React.FC<DeviceSelectorProps> = ({ onChange, value }) => {
  const options: RadioGroupOption<Device>[] = Object.entries(DEVICE_LABELS).map(
    ([type, label]) => ({
      label: (
        <Flex
          className={classNames(styles['checkbox-content'], {
            [styles['checkbox-content-active']]: type === value,
          })}
          spacing={12}
          align="center"
          justify="space-between"
        >
          <Typography.Text size={5} strong>
            {label}
          </Typography.Text>
        </Flex>
      ),
      value: type as Device,
    })
  );

  return (
    <Radio.Group
      className={styles.group}
      itemClassName={(isActive) =>
        isActive ? classNames(styles.item, styles['item-active']) : styles.item
      }
      options={options}
      name="device"
      onChange={onChange}
      value={value}
    />
  );
};

export { DeviceSelector };
