import { WidgetTypes, useCreatePay } from '../..';
import React from 'react';
import { Payment } from 'shared';
import { Button, Flex, Modal } from 'shared/_ui';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type PaymentModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  currentPayment: Payment | null;
};

const PaymentModal: React.FC<PaymentModalProps> = ({
  currentPayment,
  isOpen,
  setIsOpen,
}) => {
  const { handleClose } = useCreatePay({
    currentPayment,
    isOpen,
    setIsOpen,
  });

  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Flex spacing={10} direction="column" className={styles.flex}>
      {currentPayment?.paymentUrl &&
        currentPayment.widgetType === WidgetTypes.PAYCOS && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
      {currentPayment?.paymentUrl &&
        currentPayment.widgetType === WidgetTypes.OXPROCESSING && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
      {currentPayment?.paymentUrl &&
        currentPayment.widgetType === WidgetTypes.PAYZE && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
      {currentPayment?.widgetType &&
        currentPayment.widgetType === WidgetTypes.U_KASSA && (
          <div id="modalUkass" className={styles.container}>
            <div id="payment-form"></div>
          </div>
        )}
      {currentPayment?.widgetType &&
        currentPayment.widgetType === WidgetTypes.RU_CLOUDPAYMENTS && (
          <div id="modalPaycos" className={styles.container}></div>
        )}
        <Button
          type='primary'
          onClick={handleClose}
          className={styles.button}
        >
          {t('banner.successfulPayment.button')}
        </Button>
        </Flex>
    </Modal>
  );
};

export { PaymentModal };
