import React from 'react';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import { Portal } from '../Portal';
import { useRefContext } from '../../context';
import styles from './styles.module.scss';
import { REF_IDS } from '../../consts';

type DropdownPosition =
  | 'top-left'
  | 'top'
  | 'top-right'
  | 'bottom-left'
  | 'bottom'
  | 'bottom-right';

type DropdownProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  position?: DropdownPosition;
  className?: string;
  triggerClassName?: string;
  contentClassName?: string;
  offset?: number;
  disabled?: boolean;
  isOpen?: boolean | null;
  setIsOpen?: (value: boolean) => void;
};

export type DropdownHandles = {
  open: () => void;
  close: () => void;
};

const Dropdown = React.forwardRef<DropdownHandles, DropdownProps>(
  (
    {
      children,
      content,
      position = 'bottom',
      className,
      triggerClassName,
      contentClassName,

      offset = 12,
      disabled = false,
      isOpen,
      setIsOpen,
    },
    ref
  ) => {
    const { refs } = useRefContext();

    const [isVisible, setIsVisible] = React.useState<boolean | null>(null);
    const [dynamicPosition, setDynamicPosition] =
      React.useState<DropdownPosition>(position);
    const [contentStyle, setContentStyle] =
      React.useState<React.CSSProperties>();
    const triggerRef = React.useRef<HTMLButtonElement>(null);
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const contentRef = React.useRef<HTMLDivElement>(null);

    React.useImperativeHandle(ref, () => ({
      open: () => setIsVisible(true),
      close: () => setIsVisible(false),
    }));

    React.useEffect(() => {
      if (isOpen !== undefined) {
        setIsVisible(isOpen);
      }
    }, [isOpen]);

    React.useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node) &&
          contentRef.current &&
          !contentRef.current.contains(event.target as Node) &&
          isVisible
        ) {
          setIsVisible(false);
          setIsOpen?.(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isVisible]);

    React.useEffect(() => {
      adjustPosition(position);
    }, [position, children]);

    React.useEffect(() => {
      setContentStyle(getDropdownStyles(dynamicPosition));
    }, [dynamicPosition]);

    React.useEffect(() => {
      if (isVisible) {
        const listener = debounce(() => {
          setContentStyle(getDropdownStyles(dynamicPosition));
        }, 100);

        window.addEventListener('resize', listener);

        return () => {
          listener.cancel();
          window.removeEventListener('resize', listener);
        };
      }
    }, [dynamicPosition, isVisible]);

    React.useEffect(() => {
      const listener = debounce(
        () => {
          setContentStyle(getDropdownStyles(dynamicPosition));
        },
        isVisible ? 0 : 200
      );

      refs.current[REF_IDS.LAYOUT]?.addEventListener?.('scroll', listener);

      return () => {
        listener.cancel();
        refs.current[REF_IDS.LAYOUT]?.addEventListener?.('scroll', listener);
      };
    }, [dynamicPosition, isVisible]);

    const adjustPosition = (position: DropdownPosition) => {
      const basePosition = position.includes('top') ? 'top' : 'bottom';

      const availablePositions: DropdownPosition[] = [
        'top-left',
        'top',
        'top-right',
        'bottom-left',
        'bottom',
        'bottom-right',
      ].sort((a, b) => {
        if (a === position) return -1;
        if (b === position) return 1;

        if (a === basePosition) return -1;
        if (b === basePosition) return 1;

        const aBaseMatch = a.startsWith(basePosition);
        const bBaseMatch = b.startsWith(basePosition);
        if (aBaseMatch && bBaseMatch) return 0;
        if (!aBaseMatch && !bBaseMatch) return 0;

        if (aBaseMatch) return -1;
        if (bBaseMatch) return 1;

        return 0;
      }) as DropdownPosition[];

      const startPositionIndex = availablePositions.indexOf(position);
      let bestPosition = position;

      for (let i = 0; i < availablePositions.length; i++) {
        const currentIndex =
          (startPositionIndex + i) % availablePositions.length;
        const testPosition = availablePositions[currentIndex];

        if (!isPositionOutOfBounds(testPosition)) {
          bestPosition = testPosition;
          break;
        }
      }

      setDynamicPosition(bestPosition);
    };

    const isPositionOutOfBounds = (position: DropdownPosition): boolean => {
      if (!triggerRef.current || !contentRef.current) return false;

      const triggerRect = triggerRef.current.getBoundingClientRect();
      const contentRect = contentRef.current.getBoundingClientRect();

      switch (position) {
        case 'top-left':
          return (
            triggerRect.top < contentRect.height ||
            window.innerWidth - triggerRect.right < contentRect.width
          );
        case 'top':
          return triggerRect.top < contentRect.height;
        case 'top-right':
          return (
            triggerRect.top < contentRect.height ||
            triggerRect.right < contentRect.width
          );
        case 'bottom-left':
          return (
            window.innerHeight - triggerRect.bottom < contentRect.height ||
            window.innerWidth - triggerRect.right < contentRect.width
          );
        case 'bottom':
          return window.innerHeight - triggerRect.bottom < contentRect.height;
        case 'bottom-right':
          return (
            window.innerHeight - triggerRect.bottom < contentRect.height ||
            triggerRect.right < contentRect.width
          );
        default:
          return false;
      }
    };

    const getTransformOrigin = (position: DropdownPosition) => {
      switch (position) {
        case 'top-left':
          return 'left bottom';
        case 'top':
          return 'center bottom';
        case 'top-right':
          return 'right bottom';
        case 'bottom-left':
          return 'left top';
        case 'bottom':
          return 'center top';
        case 'bottom-right':
          return 'right top';
        default:
          return 'center';
      }
    };

    const getDropdownStyles = (position: DropdownPosition) => {
      const styles: React.CSSProperties = {
        transformOrigin: getTransformOrigin(position),
      };

      if (triggerRef.current && contentRef.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect();
        const contentRect = contentRef.current.getBoundingClientRect();

        const triggerCenterX =
          triggerRect.x + triggerRect.width / 2 + window.scrollX;

        switch (position) {
          case 'top-left':
            styles.left = `${triggerRect.left}px`;
            styles.bottom = `${
              window.innerHeight - triggerRect.top + offset - window.scrollY
            }px`;
            //styles.maxHeight = `${triggerRect.top - offset * 2}px`;
            break;
          case 'top':
            styles.left = `${triggerCenterX - contentRect.width / 2}px`;
            styles.bottom = `${
              window.innerHeight - triggerRect.top + offset - window.scrollY
            }px`;
            //styles.maxHeight = `${triggerRect.top - offset * 2}px`;
            break;
          case 'top-right':
            styles.left = `${triggerRect.right - contentRect.width}px`;
            styles.bottom = `${
              window.innerHeight - triggerRect.top + offset - window.scrollY
            }px`;
            //styles.maxHeight = `${triggerRect.top - offset * 2}px`;
            break;
          case 'bottom-left':
            styles.left = `${triggerRect.left}px`;
            styles.top = `${triggerRect.bottom + offset + window.scrollY}px`;
            //styles.maxHeight = `${
            //  window.innerHeight - triggerRect.bottom - offset * 2
            //}px`;
            break;
          case 'bottom':
            styles.left = `${triggerCenterX - contentRect.width / 2}px`;
            styles.top = `${triggerRect.bottom + offset + window.scrollY}px`;
            //styles.maxHeight = `${
             // window.innerHeight - triggerRect.bottom - offset * 2
            //}px`;
            break;
          case 'bottom-right':
            styles.left = `${triggerRect.right - contentRect.width}px`;
            styles.top = `${triggerRect.bottom + offset + window.scrollY}px`;
            //styles.maxHeight = `${
            //  window.innerHeight - triggerRect.bottom - offset * 2
            //}px`;
            break;
        }
      }

      return styles;
    };

    const toggleDropdown = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (!disabled) {
        setIsVisible((prev) => !prev);
        setIsOpen?.(!isOpen);
      }
    };

    const _dropdownClassName = classNames(className, styles.dropdown, {
      [styles['dropdown-disabled']]: disabled,
    });
    const _contentClassName = classNames(
      contentClassName,
      styles.content,
      {
        [styles['content-visible']]: isVisible === true,
        [styles['content-hidden']]: isVisible === false,
      },
      styles[`content-${dynamicPosition}`]
    );

    return (
      <span ref={dropdownRef} className={_dropdownClassName}>
        <span
          className={classNames(triggerClassName, styles.trigger)}
          ref={triggerRef}
          onClick={toggleDropdown}
        >
          {children}
        </span>
        <Portal>
          <div
            ref={contentRef}
            className={_contentClassName}
            style={contentStyle}
          >
            <div className={styles.container}>{content}</div>
          </div>
        </Portal>
      </span>
    );
  }
);

export { Dropdown };
