import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import { Button, Flex, Image, Modal, Tag, Typography } from 'shared/_ui';
import classNames from 'classnames';
import { GuideSlide } from 'entities/Guide';
import { Success } from 'shared/_ui/Icon/Success';
import { useTranslation } from 'react-i18next';
import { useSliderContext } from '../../model';
import { useFetchUserInfo } from 'entities/Connection';
import { getTariffType } from 'entities/Tariff';
import { useUserData } from 'entities/User';
import { useModal, useStoreUiControls } from 'shared/lib';
import drone from 'shared/assets/images/drone-mini.svg';
import { CONTROL_IDS, REF_IDS } from 'shared/consts';
import { useRefContext } from 'shared/context';
import { SlideProps } from 'entities/Guide';

const SuccessSlide: React.FC<SlideProps> = ({ className, isActive, number }) => {
  const { t } = useTranslation();

  const { device } = useSliderContext();

  const { close, setData } = useStoreUiControls(CONTROL_IDS.GUIDE);

  const { isMount, isOpen, openModal, closeModal } = useModal();

  const { data } = useFetchUserInfo(null);

  const { refs } = useRefContext();

  const { userData, setUserData } = useUserData();

  const tariffType = data && getTariffType(data.tariff);

  const isFreePremium = tariffType === 'free-premium';

  React.useEffect(() => {
    if (!isActive) return;

    const timer = window.setTimeout(() => {
      onOk();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isActive]);

  useEffect(() => {
    setTimeout(() => {
      refs.current[REF_IDS.LAYOUT]?.scrollTo({ top: 0, behavior: 'smooth' });
    });
    window.setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }, []);

  const onFinish = () => {
    closeModal();
    setData({ isConnectionCreate: false });
    close();
  };

  const onOk = () => {
    if (isFreePremium && userData.isNew && isActive) {
      openModal();
    } else {
      onFinish();
    }

    setUserData({
      isNew: false,
      prevPlan: data ? tariffType! : userData.prevPlan,
      lastPlanChangeDate: data ? data.paidTo : userData.lastPlanChangeDate,
    });
  };

  return (
    <>
      <GuideSlide
        className={classNames(className, styles.slide)}
        body={
          <Flex
            className={styles.container}
            spacing={32}
            direction="column"
            align="center"
          >
            <Success className={styles.success} />
            <Typography.Title className={styles.title} level={2}>
              {t('installation.finish')}
            </Typography.Title>
            <Tag color="gray">{t(`guide.success.${device}`)}</Tag>
          </Flex>
        }
        controls={
          <Button onClick={onOk} className={styles.ok} type="primary">
            {t('shared.text.ok')}
          </Button>
        }
      />
      {isMount && (
        <Modal isOpen={isOpen} onClose={onFinish}>
          <Flex
            className={styles['modal-content']}
            direction="column"
            spacing={32}
            align="center"
          >
            <Image className={styles.image} alt="drone" src={drone} />
            <Flex
              className={styles.text}
              align="center"
              direction="column"
              spacing={32}
            >
              <Typography.Title level={1}>
                {t('guide.gift.title')}
              </Typography.Title>
              <Flex align="center" direction="column" spacing={12}>
                <Typography.Text size={2}>
                  {t('guide.gift.subtitle')}
                </Typography.Text>
                <Typography.Text type="secondary" size={2}>
                  {t('guide.gift.description')}
                </Typography.Text>
              </Flex>
            </Flex>
            <Button onClick={onFinish} className={styles.thanks} type="primary">
              {t('guide.gift.thanks')}
            </Button>
          </Flex>
        </Modal>
      )}
    </>
  );
};

export { SuccessSlide };
