import React from 'react';
import {
  Button,
  Dropdown,
  Flex,
  Profile,
  Triangle,
  Typography,
} from 'shared/_ui';
import { clearStorages } from 'shared';
import {
  CONTROL_IDS,
  getRouteLogin,
} from 'shared/consts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserActions } from 'entities/User';
import styles from './styles.module.scss';
import { useOpen, useStoreUiControls } from 'shared/lib';

interface Props {
  languageSwitcherSlot: React.ReactNode;
}

const UserProfileDropdown = ({ languageSwitcherSlot }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isOpen, setIsOpen } = useOpen(null);

  const { open: openPromoCodeModal } = useStoreUiControls(
    CONTROL_IDS.PROMOCODE_MODAL
  );

  /*const { open: openReferralLinkModal } = useStoreUiControls(
    CONTROL_IDS.REFLINK_MODAL
  );*/

  const { open: openHowItWorksModal } = useStoreUiControls(
    CONTROL_IDS.HOW_IT_WORKS_MODAL
  );

  const { logout } = useUserActions();

  const onHowItWorksClick = () => {
    openHowItWorksModal();
    setIsOpen(false);
  };

  const onPromoCodeEnter = () => {
    openPromoCodeModal();
    setIsOpen(false);
  };

  /*const onFriendBring = () => {
    openReferralLinkModal();
    setIsOpen(false)
  };*/

  const onLogout = () => {
    logout();
    clearStorages(true);
    navigate(getRouteLogin());
  };

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      position="bottom-right"
      contentClassName={styles.content}
      content={
        <Flex direction="column">
          {languageSwitcherSlot}
          <Flex direction="column" spacing={20} className={styles.group}>
            <Typography.Link
              onClick={onHowItWorksClick}
              className={styles.link}
            >
              <Typography.Text type="default" size={2}>
                {t('navigation.howItWorks')}
              </Typography.Text>
            </Typography.Link>
          </Flex>
          <Flex direction="column" spacing={20} className={styles.group}>
            <Typography.Link onClick={onPromoCodeEnter} className={styles.link}>
              <Typography.Text type="default" size={2}>
                {t('navigation.enterPromoCode')}
              </Typography.Text>
            </Typography.Link>
          </Flex>
          <Flex direction="column" spacing={10} className={styles.group}>
            <Typography.Text type="default">
              {t('navigation.support')}:
            </Typography.Text>
            <Typography.Link href="mailto:support@privategatevpn.com">
              support@privategatevpn.com
            </Typography.Link>
            <Typography.Link
              target="_blank"
              href="https://t.me/PrivateGateSupport_bot"
            >
              https://t.me/PrivateGateSupport_bot
            </Typography.Link>
          </Flex>
          <Flex direction="column" spacing={10} className={styles.group}>
            <Typography.Link onClick={onLogout}>
              <Typography.Text type="default" size={2}>
                {t('navigation.logout')}
              </Typography.Text>
            </Typography.Link>
          </Flex>
        </Flex>
      }
    >
      <Button
        className={styles.button}
        type="text"
        shape="round"
        size="large"
        icon={
          <Flex spacing={5} align="center">
            <Profile className={styles.profile} />
            <Triangle className={styles.triangle} />
          </Flex>
        }
      >
        {t('navigation.usefulLinks')}
      </Button>
    </Dropdown>
  );
};

export { UserProfileDropdown };
