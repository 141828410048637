import React from 'react';
import styles from './styles.module.scss';
import { useModal } from 'shared/lib';
import { Button, Flex, Modal, Typography } from 'shared/_ui';
import { PaymentForm } from 'features/PaymentForm';
import { ITariff } from 'shared/api';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Tariff } from '../Tariff';
import { getTariffType } from 'entities/Tariff';
import { PromoCodeForm } from 'features/PromoCodeForm';
import { getPrice } from 'shared/api/currency';

type AvailableTariffProps = {
  baseTariff: ITariff;
  tariff: ITariff;
  current: boolean;
  type?: 'default' | 'popular' | 'beneficial';
};

const AvailableTariff: React.FC<AvailableTariffProps> = ({
  baseTariff,
  tariff,
  current,
  type = 'default',
}) => {

  const { t } = useTranslation();

  const {
    isMount: isPaymentModalMount,
    isOpen: isPaymentModalOpen,
    closeModal: closePaymentModal,
    openModal: openPaymentModal,
  } = useModal();

  const {
    isMount: isPromoCodeModalMount,
    isOpen: isPromoCodeModalOpen,
    closeModal: closePromoCodeModal,
    openModal: openPromoCodeModal,
  } = useModal();

  return (
    <>
      <Tariff
        className={classNames(styles.tariff, styles[`tariff-${type}`], {
          [styles[`current`]]: current,
        })}
        baseTariff={baseTariff}
        tariff={tariff}
        type={type}
        control={
          getTariffType(tariff) === 'free' ? (
            <></>
          ) : (
            <Button
              type="primary"
              onClick={openPaymentModal}
              className={styles.buy}
            >
              <Typography.Text size={2} strong>
                {`${t('tariff.renewFor')} ${getPrice(tariff.price)}`}
              </Typography.Text>
            </Button>
          )
        }
      />
      {isPaymentModalMount && (
        <Modal isOpen={isPaymentModalOpen} onClose={closePaymentModal}>
          <Flex className={styles.body} direction="column" spacing={32}>
            <Flex direction="column" spacing={16}>
              <PaymentForm tariffId={tariff.id} />
              <Button
                onClick={openPromoCodeModal}
                type="text"
                className={styles.promocode}
              >
                <Typography.Text type="secondary">
                  {t('promoCode.have')}
                </Typography.Text>
              </Button>
            </Flex>
            <Tariff
              className={styles.paid}
              baseTariff={baseTariff}
              tariff={tariff}
            />
          </Flex>
          <Typography.Text
            className={styles.disclaimer}
            size={1}
            type="secondary"
          >
            {t('payment.disclaimer')}
          </Typography.Text>
        </Modal>
      )}
      {isPromoCodeModalMount && (
        <Modal
          className={styles['promocode-modal']}
          isOpen={isPromoCodeModalOpen}
          onClose={closePromoCodeModal}
          logo={false}
        >
          <Flex direction="column" spacing={42}>
            <Typography.Title level={1}>
              {t('promoCode.yourPromoCode')}
            </Typography.Title>
            <PromoCodeForm />
          </Flex>
        </Modal>
      )}
    </>
  );
};

export { AvailableTariff };
