import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type CheckboxProps = {
  children?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const Checkbox: React.FC<CheckboxProps> & { Group: typeof CheckboxGroup } = ({
  children,
  checked,
  disabled,
  onChange,
  className,
}) => {
  const classNamePrefix = 'checkbox';
  const _className = classNames(className, styles[classNamePrefix]);

  return (
    <label className={_className}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={styles.input}
      />
      <span className={styles.checkmark} />
      {children}
    </label>
  );
};

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

type CheckboxGroupProps = {
  options: string[] | Option[];
  disabled?: boolean;
  value?: string[];
  onChange?: (value: string, isChecked: boolean) => void;
  className?: string;
};

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  options,
  disabled,
  value,
  onChange,
  className,
}) => {
  const classNamePrefix = 'checkbox-group';
  const _className = classNames(className, styles[classNamePrefix]);

  const handleChange = (optionValue: string, isChecked: boolean) => {
    onChange?.(optionValue,isChecked);
  };

  return (
    <div className={_className}>
      {options.map((option, index) => {
        const {
          label,
          value: optionValue,
          disabled: optionDisabled = false,
        } = typeof option === 'string'
          ? { label: option, value: option }
          : option;
        const isChecked = value?.includes(optionValue);

        return (
          <Checkbox
            key={index}
            checked={isChecked}
            disabled={disabled || optionDisabled}
            onChange={(e) => handleChange(optionValue, e.target.checked)}
          >
            {label}
          </Checkbox>
        );
      })}
    </div>
  );
};

Checkbox.Group = CheckboxGroup;

export { Checkbox };
