export const translationEn = {
  shared: {
    error: {
      base: 'An error occurred',
      invalidRequest: 'Invalid request content',
      required: 'Required field',
      email: 'Invalid email',
      minSixLetter: 'Minimum 6 characters',
      copy: 'An error occurred while copying',
      data: 'An error occurred while loading data',
      video: 'Your browser does not support the video tag',
      timeSessionExpired: 'Session timed out',
      download: 'An error occurred while downloading the file',
    },
    message: {
      copy: 'Copied',
    },
    text: {
      yes: 'Yes',
      no: 'No',
      empty: 'No data',
      upTo: 'Up to',
      checkConnectionAndTryAgain:
        'Check your internet connection and try again',
      repeat: 'Try again',
      noData: 'No data',
      continue: 'Continue',
      back: 'Back',
      finish: 'Done',
      ok: 'OK',
    },
    plural: {
      days_one: '{{count}} day',
      days_other: '{{count}} days',
      months_one: '{{count}} month',
      months_other: '{{count}} months',
    },
  },
  theme: {
    toggle: 'Switch theme',
  },
  banner: {
    premiumExpiration: {
      title: 'Hello👋',
      subtitle:
        'Just a gentle reminder that your Premium subscription will expire in 3 days.',
      description: 'Renew now to avoid any interruptions 👇',
      button: 'Renew Premium',
    },
    premiumExpiration_1: {
      title: 'Hi ⏳ Your Premium subscription expires tomorrow.',
      subtitle: `Imagine how much you could lose by going back to the free version: The current VPN on X-Ray technology, which can't be blocked, will stop working.
        
        Renewing now ensures you continue to have secure and unlimited internet access. Make the choice in favour of your convenience and security.
        `,
      description: `Renew right now, don't wait until the last minute! 👇`,
      button: 'Renew Premium',
    },
    premiumExpiration_9: {
      title: `Hi👋 Remember
that we thoughtfully
gave you
14 days of Premium
right from the start?`,
      subtitle: `After the end of the trial period you can continue using the VPN for free, but you will need to install our alternative VPN (no app). X-Ray VPN protocol (via app) is only available on Premium.
        
If you're happy with the current performance of our VPN, we recommend paying now and not thinking about it at the worst possible moment.
        `,
      description:
        'To avoid breaking your head and continue to switch on your VPN with one button, pay for your subscription now 👇',
      button: 'Renew Premium',
    },
    premiumExpired: {
      title: `😿 Your tariff has changed 
to free`,
      subtitle: `If you had a VPN through the app (X-Ray technology) installed, it stopped working. To continue using VPN via app you need to go back to Premium.
      
      For free access you need to install our alternative VPN protocol without apps in the settings of your device. You can do this via ‘Profile’.
      `,
      description:
        'To go back to the Premium plan and continue using the VPN without the headache, click ‘Buy VPN’ 👇',
      button: 'Extend Premium',
    },
    premiumExpired_7: {
      title: `VPN stopped working?`,
      subtitle: `That's because you have a free plan with a speed limit. For free access you need to install our alternative VPN protocol without apps. You can do this via ‘Profile’.`,
      description:
        'To ‘get things back to normal’ and continue to enjoy unlimited internet access, subscribe now 👇',
      button: 'Buy Premium',
    },
    speedLimit: {
      title: `VPN 
      stopped working?`,
      subtitle: `That's because you have a free plan with a speed limit. For free access you need to install our alternative VPN protocol without apps. You can do this via ‘Profile’.`,
      description:
        'To ‘get things back to normal’ and continue to enjoy unlimited internet access, subscribe now 👇',
      button: 'Buy Premium',
    },
    successfulPayment: {
      heading: 'Thank you for choosing us! Payment was successful',
      hint: 'To return to the home page click on the button:',
      button: 'Home',
    },
    noXrayPopup: "X-Ray VPN protocol (via app) is available only on Premium plan.\nTo continue using a VPN that cannot be blocked, please buy Premium plan.",
  },
  society: {
    facebook: 'https://www.facebook.com/groups/privategatevpn',
    telegram: 'https://t.me/privategatevpn',
    vk: 'https://vk.com/privategate_vpn',
    twitter: 'https://twitter.com/privategate_vpn',
    youtube: 'https://youtube.com/@PrivateGateEN?si=ovsYcKMzX25r-fSE',
  },
  links: {
    offer: 'https://privategatevpn.com/offer',
    privacyPolicy: 'https://privategatevpn.com/privacypolicy',
  },
  navigation: {
    faq: 'FAQ',
    howToInstall: 'How to install',
    usefulLinks: 'Useful links',
    howItWorks: 'How it works',
    changePassword: 'Change password',
    enterPromoCode: 'Enter promo code',
    bringFriend: 'Bring a friend',
    support: 'Support',
    logout: 'Logout',
  },
  footer: {
    offer: 'Offer',
    privacyPolicy: 'Privacy Policy',
  },
  howItWorks: {
    howItWorks: {
      title: 'How it works',
      action: 'Start installation',
      description: `
      Private Gate is using X-Ray (VLESS) technology for highly censored regions. The latest VPN protocol that masks your internet traffic.
  
  ✅ Cannot be blocked
  ✅ Works always and everywhere
  ✅ Easy and fast installation
  ✅ Stable
  ✅ Works on any device

  You need to get the configuration of the VPN server and install it to the special app to make this protocol work.
  Start installing now by using instructions on this page:
  `,
    },
    gift: {
      title: '14 days Premium for Free',
      action: 'Switch to Premium',
      description: `Hi👋 Remember that we thoughtfully gave you 14 days of Premium from the start?

After the end of the trial period you can continue using the VPN for free, but you will need to install our alternative VPN (without apps). 
X-Ray VPN protocol (via app) is only available on Premium.

If you're happy with the current performance of our VPN, we recommend paying now and not thinking about it at the worst possible moment. 
To avoid breaking your head and keep turning on your VPN with one button, pay for your subscription now 
`,
    },
    free: {
      title: 'Free VPN',
      action: 'Buy VPN',
      description: `😿 Your plan has changed to FREE.

      If you had a VPN installed through the app (X-Ray technology), it stopped working. To continue using VPN through the app, you need to renew the Premium.

For free access, you need to install the alternative app-free VPN protocol in your device's settings. You can do this via creating new connection.

To return to the Premium plan and continue using VPN without headaches, click "Buy VPN" 👇
`,
    },
    bot: {
      title: 'Our Telegram bot',
      links: `Launch the bot at the link:--https://t.me/PrivateGateVPNbot`,
      description: `Not comfortable using it through your browser? We have a convenient bot in Telegram, through which you can also get VPN.

      `,
    },
    chat: {
      title: 'Our Telegram Channel and Chat',
      links: `📣 Our channel--https://t.me/privategatevpn ||
💬 Chat--https://t.me/privategatevpn_chat `,
      description: `Let's always stay connected 🤙

📣 Subscribe to our Telegram channel, where we share unique offers, promotions, news, and lifehacks for safe internet use.

💪 Join now and start receiving exclusive offers and benefits today!

We are waiting for you💚
`,
    },
    feedback: {
      title: 'Leave the feedback',
      links: `👉 All you have to do is take a 2 minute survey via the link--https://forms.gle/Zk5w2PvDdbtfFhYn7`,
      description: `If you have any ideas on how we can improve your Private Gate VPN adventure, please share them. 
We value your opinion and are here to help with any questions you may have. 

`,
    },
  },
  authentication: {
    notify: {
      successRegister: 'Account successfully created',
      passwordResetLinkSent: 'Password reset link sent to email',
      invitationAlreadySent:
        'An invitation has already been sent to the provided email address',
      userNotFound: 'User not found',
      wrongCaptcha: 'Captcha code entered incorrectly',
      passwordChanged: 'Password successfully changed',
      alreadySend:
        'An invitation has already been sent to the specified email address',
      onDisagreeance: 'You cannot sign up if you do not agree to the Offer and Privacy Policy'
    },
    login: {
      title: 'Login',
      noAccountYet: 'Don’t have an account yet?',
      register: 'Register',
      error: 'Incorrect login or password',
      password: 'Password',
      loginButton: 'Log in',
    },
    registration: {
      title: 'Registration',
      agreement: 'By registering, you agree to the following documents:',
      and: 'and',
      otp: 'A one-time password for login will be sent to your email',
      offerAgreement: 'Offer',
      privacyPolicy: 'Privacy Policy',
      alreadyHaveAccount: 'Already have an account?',
      login: 'Login',
      next: 'Next',
      email: 'Email',
    },
    password: {
      title: 'Password',
      new: 'New password',
      current: 'Current password',
      create: 'Create a password',
      change: 'Change password',
      restore: 'Restore password',
      reset: 'Reset password',
      forgot: 'Forgot password?',
      enter: 'Enter password',
      createButton: 'Create password',
      changeButton: 'Change password',
      alreadyHavePassword: 'Already have a password?',
      passShouldNotMatch: 'Passwords should not match',
      wrong: 'Incorrect password',
    },
    otp: {
      title: "Enter the confirmation code",
      code: "Check your inbox and spam folder for the verification code, sent to:",
      nocode: "Didn't get the code?",
      resend: "Resend",
      resendSuccess: "Code is resent successfully",
      next: "Next",
      wrong: "Wrong code",
      wait: "You can send requests only once in 60 seconds. Wait ",
      seconds: " seconds to send new request"
    },
    mail: {
      confirmationLinkSent: 'Confirmation link sent to your email',
      description:
        'To complete registration, go to your email and follow the link.',
      checkSpam: 'If you do not see the email – check your spam folder.',
      didNotReceiveEmail: 'Didn’t receive the email?',
      sendAgain: 'Send again',
      sendCooldown:
        'Invite to the provided email is already sent. Please try again later.',
      countdown: 'Next try available in ',
    },
    restore: {
      send: 'Send',
      email: 'Email',
      instructionLinkSent: 'Password reset instructions sent to your email',
      description:
        'To complete password reset, go to your email and follow the instructions.',
      captcha: 'Captcha',
      enterCaptcha: 'Enter the characters from the image',
      reloadCaptcha: 'Refresh',
    },
  },
  instruction: {
    step: 'Step',
    final: 'Final',
    image: 'Instruction',
  },
  provider: {
    name: {
      rf: 'Russian bank cards',
      cloud: 'Bank card (except Russia)',
      crypto: 'Payment with cryptocurrency',
      payze: 'International bank cards',
      ruCloudPayments: "Russian bank cards"
    },
  },
  connection: {
    full: 'Unable to create a new connection. The maximum number of connections has been reached. Delete one of the existing connections to create a new one',
    error: {
      create: 'There was an error connecting',
    },
    actions: 'Actions',
    downloadConfig: 'Download VPN configuration',
    whichDevice:
      'To which device do you want to download the VPN configuration file?',
    howToInstall: 'How to install',
    changeDevice: 'Change device',
    delete: 'Delete',
    confirm: {
      changeDevice: 'Are you sure you want to change the device?',
      delete: 'Are you sure you want to delete the connection?',
    },
    notify: {
      successDeleted: 'Connection successfully deleted',
    },
    list: 'Your devices and connections',
    add: 'add device',
    addNewDevice: 'Add a new device',
    cancelAdding: 'Close',
    name: 'Private Gate',
    server: 'Connection server',
    login: 'User name',
    password: 'Password',
    mac: {
      title: {
        name: 'Display Name',
        server: 'Server Address',
        remote: 'Remote ID',
        user: 'Username',
        password: 'Password',
      },
    },
    windows: {
      title: {
        provider: 'VPN Provider',
        name: 'Connection Name',
        server: 'Server Name or Address',
        vpnType: 'VPN Type',
        dataType: 'Type of Sign-in info',
        user: 'User name',
        password: 'Password',
      },
      value: {
        provider: 'Windows (built-in)',
        vpnType: 'IKEv2',
        dataType: 'Username and password',
      },
    },
    iphone: {
      title: {
        description: 'Description',
        server: 'Server',
        remote: 'Remote ID',
        user: 'Username',
        password: 'Password',
      },
    },
    android: {
      title: {
        server: 'Server',
        user: 'Username',
        password: 'Password',
      },
    },
  },
  country: {
    selectCountry: 'Select the country to connect to',
    selectCountryDescription:
      'The country to which your device will be connected, in the future you can change:',
    name: {
      empty: '🏳️ Not selected',
      kz: '🇰🇿 Kazakhstan',
      ka: '🇰🇿 Kazakhstan',
      ge: "🇩🇪 Germany",
      un: "🇦🇪 Arab Emirates",
      de: '🇩🇪 Germany',
      us: '🇺🇸 USA',
      ru: '🇷🇺 Russia',
      nl: '🇳🇱 Netherlands',
      sg: '🇸🇬 Singapore',
      fr: '🇫🇷 France',
      gb: '🇬🇧 United Kingdom',
      ae: '🇦🇪 Arab Emirates',
      tr: '🇹🇷 Turkey',
    },
    confirm: {
      change: 'Are you sure you want to change the country?',
    },
    notify: {
      successChanged: 'Country successfully changed',
    },
  },
  payment: {
    title: 'Payment',
    paymentMethod: 'Payment method',
    selectPaymentMethod: 'Select payment method',
    pay: 'Pay',
    disclaimer:
      'The currency of the debiting may differ, but the total actual amount does not change from this. To complete the payment, click on the "Pay" button.',
  },
  tariff: {
    name: {
      free: 'Free',
      freePremium: 'Free premium version',
      premium: 'Premium',
    },
    bandwidth: {
      bytes: 'Bits/s',
      kbytes: 'Kbits/s',
      mbytes: 'Mbits/s',
    },
    params: {
      remaining: 'Remaining',
      speed: 'Speed',
      deviceCount: 'Number of devices',
      discount: 'Discount',
      monthly: 'Monthly',
      free: 'Free',
      paidTo: 'Tariff paid until',
    },
    activateFor: 'Subscribe to Premium for',
    renewFor: 'Renew Premium for',
    renew: 'Renew Premium',
    change: 'Change tariff',
    connectFree: 'Connect for free',
    popularChoice: 'Popular choice',
    beneficial: 'Beneficial!',
    availableList: 'Available plans',
    current: 'Selected tariff',
  },
  currency: {
    usd: '$',
    rub: '₽',
  },
  promoCode: {
    title: 'Promo code',
    enter: 'Enter promo code',
    enterPromoCodeToGetOrExtendPremium:
      'Get or extend Premium tariff by entering a promo code',
    activateButton: 'Activate',
    yourPromoCode: 'Your promo code',
    confirmButton: 'Confirm',
    have: 'Have a promo code?',
    apply: 'Apply',
    error: 'The promo code is not correct or has already been activated',
    success: 'Yay, your promo code is activated!',
  },
  installation: {
    app: {
      label: 'Via the X-Ray app',
      time: 'Less than a minute',
      description:
        'X-Ray technology (VLESS) for regions with high levels of censorship. The latest VPN protocol that masks your traffic on the Internet.',
      benefits:
        'Cannot be blocked*Works anytime, anywhere*Easy and quick installation*Stable*Works on any device',
      freeDescription: `X-Ray VPN protocol (via app) is available only on Premium plan.

To continue using a VPN that cannot be blocked, please buy Premium plan.
        `,
      recommended: 'Recommended',
    },
    manual: {
      label: 'Manual Setup',
      time: '≈ 4 minutes, but more reliable',
      description:
        'No apps. Apps can track your actions and share data with advertisers and other third parties. Installing a VPN manually directly on your device without third-party apps.',
    },
    config: {
      label: 'Install VPN configuration',
      time: '≈ 2 minutes and you’re free',
      description:
        'No apps. Apps can track your actions and send data to advertisers and other third parties. We provide a clean VPN configuration that you can install yourself directly on your device without third-party apps.',
    },
    select: 'Choose installation method',
    description:
      'Each is suitable for different preferences: from simplicity to full control.',
    finish:
      'Congratulations!\nYou have successfully installed Private Gate VPN!',
  },
  device: {
    select: 'Select your device',
    selectDeviceToProtect:
      'Start safe internet surfing by selecting a device to protect',
  },
  xray: {
    connection: 'App X-Ray',
    remaining: 'Remaining: ',
    duplicateConnection:
      'You have already created a reference key for the selected country. Use the key issued earlier!',
    createError: 'An error occurred while creating a connection',
    countryChangeHint:
      '⚠️ If you change VPN output country, the current connection will no longer work. On Premium tariff you can create 3 connections with different countries and use them simultaneously on different devices. Are you sure you want to change the output VPN country on the current connection?',
  },
  'non-app': {
    connection: 'NO-Apps',
  },
  guide: {
    app: {
      title: 'Follow the instructions',
      foxray: 'Download FoXray',
      samsung: 'Link to the Samsung Store',
      microsoft: 'Link to the Microsoft Store',
      streisand: 'Download Streisand',
      hiddifynext: 'Download Hiddify-Next',
      rayn: 'Download 2RayN',
      linkKey: 'Link key'
    },
    manual: { title: 'Follow the instructions for “Manual setup”' },
    config: {
      title: 'Follow the instructions for “Through Configurations”',
      download: 'Download VPN configuration file',
      downloadApp: 'Download app',
    },
    description: 'Use the video guide',
    stepByStep: 'Step by step instructions:',
    mac: {
      app: {
        step: {
          1: {
            title: 'Install an app',
            description: {
              1: 'Install the app FoXray from App Store.',
              2: 'Install it on MacOS.',
            }
          },
          2: {
            title: 'Launch the app',
            description: {
              1: 'Open the installed app.',
              2: 'Agree with privacy policy if needed.'
            },
          },
          3: {
            title: 'Copy a VPN key',
            description: {
              1: 'Copy the VPN link-key for configuring VPN:',
            },
          },
          4: {
            title: 'Install the key',
            description: {
              1: 'Open the installed app.',
              2: 'Click a button “New Profile” in the main menu.',
              3: 'Press buttons “Add from clipboard” and "Allow pasting" if needed.',
            },
          },
          5: {
            title: 'Launch the VPN',
            description: {
              1: 'Click a button “Launch” for an imported key.',
              2: '“Allow” adding a VPN configuration if needed.\n\nCongratulations, the VPN is installed now!\nSurf thorugh the internet safely with Private Gate!',
            },
          },
        },
      },
    },
    windows: {
      app: {
        step: {
          1: {
            title: 'Install an app',
            description: {
              1: 'Install the app Hiddify from Microsoft Store.',
              2: 'Install it on Windows.',
            }
          },
          2: {
            title: 'Launch the app',
            description: {
              1: 'Open the installed app.',
              2: 'Agree with privacy policy if needed.'
            },
          },
          3: {
            title: 'Copy a VPN key',
            description: {
              1: 'Copy the VPN link-key for configuring VPN:',
            },
          },
          4: {
            title: 'Install the key',
            description: {
              1: 'Open the installed app.',
              2: 'Click a button “New Profile” in the main menu.',
              3: 'Press buttons “Add from clipboard” and "Allow pasting" if needed.',
            },
          },
          5: {
            title: 'Launch the VPN',
            description: {
              1: 'Click a button “Launch” for an imported key.',
              2: '“Allow” adding a VPN configuration if needed.\n\nCongratulations, the VPN is installed now!\nSurf thorugh the internet safely with Private Gate!',
            },
          },
        },
      },
    },
    linux: {
      app: {
        step: {
          1: {
            title: 'Install an app',
            description: {
              1: 'Install the app Hiddify-Next.',
              2: 'Install it on Linux.',
            }
          },
          2: {
            title: 'Open the app',
            description: {
              1: 'Investigate the properties of the app by right-clicking on the app and make it “Executable”.',
              2: 'Open the installed app.',
              3: 'Agree with privacy policy if needed.',
            }
          },
          3: {
            title: 'Insert a key',
            description: {
              1: 'Insert the key for configuring VPN:',
            }
          },
          4: {
            title: 'Install a VPN key',
            description: {
              1: 'Open the installed app Hiddify.',
              2: 'Click a button “New Profile” in the main menu.',
              3: 'Press a button “Add from clipboard”.',
              4: 'Push "Allow pasting" if needed.',
            }
          },
          5: {
            title: 'Launch the VPN',
            description: {
              1: 'Press “Launch” for an imported key.',
              2: '“Allow” adding a VPN configuration if needed.\n\nCongratulations, the VPN is installed now!\nSurf through the internet safely with Private Gate!',
            }
          }
        }
      }
    },
    iphone: {
      app: {
        step: {
          1: {
            title: 'Install an app',
            description: {
              1: 'Go to App Store',
              2: 'Install the app Hiddify.',
            },
          },
          2: {
            title: 'Launch the app',
            description: {
              1: 'Open the installed app.',
              2: 'Agree with privacy policy if needed.'
            },
          },
          3: {
            title: 'Copy a VPN key',
            description: {
              1: 'Copy the VPN link-key for configuring VPN:',
            },
          },
          4: {
            title: 'Install the key',
            description: {
              1: 'Open the installed app.',
              2: 'Click a button “New Profile” in the main menu.',
              3: 'Press a button “Add from clipboard”.',
              4: 'Push "Allow pasting" if needed.',
            },
          },
          5: {
            title: 'Launch the VPN',
            description: {
              1: 'Click a button “Launch” for an imported key.',
              2: '“Allow” adding a VPN configuration if needed.\n\nCongratulations, the VPN is installed now!\nSurf through the internet safely with Private Gate!',
            },
          },
        },
      },
    },
    android: {
      app: {
        step: {
          1: {
            title: 'Install an app',
            description: {
              1: 'Install the app Hiddify for Google Play.',
              2: 'Install it for Android.',
            },
          },
          2: {
            title: 'Launch the app',
            description: {
              1: 'Open the installed app.',
              2: 'Agree with privacy policy if needed.',
            },
          },
          3: {
            title: 'Copy a VPN key',
            description: {
              1: 'Copy the VPN link-key for configuring VPN:',
            },
          },
          4: {
            title: 'Install the key',
            description: {
              1: 'Open the installed app Hiddify.',
              2: 'Press a button “New Profile” in the main menu.',
              3: 'Click a button “Add from clipboard”.',
              4: 'Push "Allow pasting" if needed.',
            },
          },
          5: {
            title: 'Grant permissions',
            description: {
              1: 'Permit the request for connecting.',
              2: 'A VPN key is imported!',
            },
          },
          6: {
            title: 'Launch the VPN',
            description: {
              1: 'Click a button “Launch” for an imported key.',
              2: '“Allow” adding a VPN configuration if needed.\n\nCongratulations, the VPN is installed now!\nSurf through the internet safely with Private Gate!',
            },
          },
        },
      },
    },
    success: {
      mac: 'For Mac (macOS)',
      windows: 'For PC (Windows)',
      iphone: 'For iPhone (iOS)',
      android: 'For Android',
      linux: 'For PC (GNU Linux)'
    },
    gift: {
      title: 'Hooray, you have been activated Premium tariff for 7 days!',
      subtitle: 'This is a gift from Private Gate VPN',
      description:
        'Afterwards, you can use the free tariff with speed limitation or return to the advantages of Premium subscription',
      thanks: 'Thank you!',
    },
  },
  referralLink: {
    title: "Bring a friend!",
    description: `Did you know, that you will have 7 days of Premium Subscription as a gift for every invited friend?

And it is not all:
🎁 If someone follows your link, they will have 7 days of Premium Subscription as a gift.
🎁 If invited pays for a subscription, they will have 1 month as a bonus, and you will have 3 months of Premium.`,
    link: "Here is your referral link:",
    userActivations: "Total amount of invited users:",
    userPaymentActivations: "Total amount of payments by invited users:",
    copyLink: "Copy link",
    copySuccess: "The link was successfully copied."
  }
};
