import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoginForm } from 'features/AuthByEmail';
import { AuthenticationContainer } from 'entities/Authentication';
import { useUserAuthData } from 'entities/User';
import { getRouteMain, getSignUp } from 'shared/consts';
import { Flex, Typography } from 'shared/_ui';

import styles from './styles.module.scss';
import { LanguageSwitch } from 'features/SwitchLanguage';
import { AuthByTelegram } from 'features/AuthByTelegram';

export const LoginPage: React.FC = () => {
  const authData = useUserAuthData();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // Это должно решаться на уровне routes
  React.useEffect(() => {
    if (authData) navigate(getRouteMain());
  }, [authData]);

  return (
    <AuthenticationContainer
      layoutControls={<LanguageSwitch />}
      title={t('authentication.login.title')}
      hint={
        <div className={styles.hint}>
          <Flex spacing={8}>
            <Typography.Text type="secondary">
              {t('authentication.login.noAccountYet')}
            </Typography.Text>
            <Typography.Link type="primary">
              <Link to={getSignUp()}>{t('authentication.login.register')}</Link>
            </Typography.Link>
          </Flex>
        </div>
      }
    >
      <LoginForm
        authProviders={() => <AuthByTelegram botName="PrivateGateVPNbot" />}
      />
    </AuthenticationContainer>
  );
};
