import React from 'react';
import { useStoreUiControls } from 'shared/lib';
import { useFetchUserInfo } from 'entities/Connection';
import { Button, Flex, QueryStatusBoundary, Typography } from 'shared/_ui';
import { getTariffType } from 'entities/Tariff';
import { useTranslation } from 'react-i18next';
import { useFetchTariffs } from 'shared/api';
import { CONTROL_IDS, REF_IDS } from 'shared/consts';
import { useRefContext } from 'shared/context';
import { Tariff } from '../Tariff';

const CurrentTariff: React.FC = () => {
  const { t } = useTranslation();

  const { refs } = useRefContext();

  const { open: openAvaliblePlans } = useStoreUiControls(
    CONTROL_IDS.AVAILABLE_PLANS
  );

  const {
    data: tariffs,
    isLoading: isTariffsLoading,
    isError: isTariffsError,
    refetch,
  } = useFetchTariffs(null);
  const {
    data: userInfo,
    isLoading: isUserInfoLoading,
    isError: isUserInfoError,
  } = useFetchUserInfo(null);

  const tariff = userInfo?.tariff;
  const baseTariff = tariffs?.[0];

  const scrollToTariffList = () => {
    const layoutRef = refs.current[REF_IDS.LAYOUT];
    const availablePlansRef = refs.current[REF_IDS.AVAILABLE_PLANS];

    if (layoutRef && availablePlansRef) {
      const scrollOffset = 32;

      const elementPosition =
        availablePlansRef.getBoundingClientRect().top + layoutRef.scrollTop;
      const offsetPosition = elementPosition - scrollOffset;

      openAvaliblePlans();

      layoutRef.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const isLoading = isTariffsLoading || isUserInfoLoading;
  const isError = isTariffsError || isUserInfoError;

  return ( tariff && getTariffType(tariff) === "free" ? <></> :
    <Flex direction="column" spacing={24}>
      <Typography.Title level={2}>{t('tariff.current')}</Typography.Title>
      <QueryStatusBoundary
        isLoading={isLoading}
        isError={isError}
        isEmpty={!tariffs?.length || !userInfo}
        refresh={refetch}
      >
        {tariff && baseTariff && (
          <Tariff
            current
            tariff={tariff}
            baseTariff={baseTariff}
            paidTo={userInfo.paidTo}
            control={
              <Button onClick={scrollToTariffList}>
                <Typography.Text size={2} strong>
                  {getTariffType(tariff) === 'free'
                    ? t('tariff.change')
                    : t('tariff.renew')}
                </Typography.Text>
              </Button>
            }
          />
        )}
      </QueryStatusBoundary>
    </Flex>
  );
};

export { CurrentTariff };
