import React from 'react';
import {
  Container,
  Facebook,
  Flex,
  Logo,
  Telegram,
  Typography,
  Youtube,
} from 'shared/_ui';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container className={styles.footer}>
      <Logo className={styles.logo} size="middle" />
      <nav className={styles.navigation}>
        <Typography.Link
          href={t('links.offer')}
          target="_blank"
          type="secondary"
        >
          <Typography.Text size={2} type="secondary">
            {t('footer.offer')}
          </Typography.Text>
        </Typography.Link>
        <Typography.Link
          href={t('links.privacyPolicy')}
          target="_blank"
          type="secondary"
        >
          <Typography.Text size={2} type="secondary">
            {t('footer.privacyPolicy')}
          </Typography.Text>
        </Typography.Link>
        <Typography.Link
          type="secondary"
          href="mailto:support@privategatevpn.com"
        >
          <Typography.Text size={2} type="secondary">
            support@privategatevpn.com
          </Typography.Text>
        </Typography.Link>
      </nav>
      <Flex spacing={20}>
        <Typography.Link
          className={styles.society}
          target="_blank"
          href={t('society.facebook')}
        >
          <Facebook />
        </Typography.Link>
        <Typography.Link
          className={styles.society}
          target="_blank"
          href={t('society.youtube')}
        >
          <Youtube />
        </Typography.Link>
        {
          <Typography.Link
            className={styles.society}
            target="_blank"
            href={t('society.telegram')}
          >
            <Telegram />
          </Typography.Link>
        }
      </Flex>
    </Container>
  );
};

export { Footer };
