import React from 'react';
import styles from './styles.module.scss';
import { InfinityIcon, Tag, Typography } from 'shared/_ui';
import {
  TariffCard,
  calculateDiscountPercentage,
  calculateMonthlyPayment,
  convertToMonths,
  getTariffName,
} from 'entities/Tariff';
import { ITariff } from 'shared/api';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { formatDateToEuro } from 'shared/lib';
import { getPrice } from 'shared/api/currency';

type TariffProps = {
  baseTariff: ITariff;
  tariff: ITariff;
  type?: 'default' | 'popular' | 'beneficial';
  className?: string;
  control?: React.ReactNode;
  current?: boolean;
  paidTo?: string | null;
};

const Tariff: React.FC<TariffProps> = ({
  baseTariff,
  tariff,
  type = 'default',
  className,
  control,
  current,
  paidTo,
}) => {
  const { t } = useTranslation();

  const months = convertToMonths(tariff.periodValue, tariff.periodName);
  const monthlyPrice = calculateMonthlyPayment(months, tariff.price);
  const discount = calculateDiscountPercentage(
    calculateMonthlyPayment(
      convertToMonths(baseTariff.periodValue, baseTariff.periodName),
      baseTariff.price
    ),
    monthlyPrice
  );
  const name = getTariffName(tariff)
    .map((part: Parameters<TFunction>) => t(...part))
    .join(' ');

  const tariffParams = [
    {
      label: t('tariff.params.monthly'),
      description: (
        <Typography.Text
          strong
        >{`${monthlyPrice ? "≈" : ""} ${getPrice(monthlyPrice)}`}</Typography.Text>
      ),
    },
    {
      label: t('tariff.params.discount'),
      description: (
        <Typography.Text strong>
          {discount > 0 ? `${discount}%` : t('shared.text.no')}
        </Typography.Text>
      ),
    },
    {
      label: t('tariff.params.speed'),
      description: (
        <Typography.Text type="success" strong>
          <InfinityIcon className={styles.infinity} />
        </Typography.Text>
      ),
    },
    {
      label: t('tariff.params.deviceCount'),
      description: (
        <Typography.Text strong>{`${t('shared.text.upTo')} ${
          tariff.numberOfAccounts
        }`}</Typography.Text>
      ),
    },
  ];

  const currentTariffParams = [
    {
      label: t('tariff.params.paidTo'),
      description: (
        <Typography.Text strong>
          {paidTo && formatDateToEuro(paidTo)}
        </Typography.Text>
      ),
      highlighted: true,
    },
    {
      label: t('tariff.params.speed'),
      description: (
        <Typography.Text type="success" strong>
          <InfinityIcon className={styles.infinity} />
        </Typography.Text>
      ),
    },
    {
      label: t('tariff.params.deviceCount'),
      description: (
        <Typography.Text strong>{`${t('shared.text.upTo')} ${
          tariff.numberOfAccounts
        }`}</Typography.Text>
      ),
    },
  ];

  return (
    <TariffCard
      className={className}
      name={name}
      price={current ? null : getPrice(tariff.price)}
      params={current ? currentTariffParams : tariffParams}
      tag={
        type === 'popular' ? (
          <Tag color="green">{t('tariff.popularChoice')}</Tag>
        ) : type === 'beneficial' ? (
          <Tag color="yellow">{t('tariff.beneficial')}</Tag>
        ) : null
      }
      control={control}
    />
  );
};

export { Tariff };
