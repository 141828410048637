import React from 'react';
import styles from './styles.module.scss';
import { GuideSlide } from 'entities/Guide';
import { Arrow, Button, Flex, Typography } from 'shared/_ui';
import { useTranslation } from 'react-i18next';
import { DeviceSelector } from 'entities/Device';
import { useSliderContext } from '../../model';
import classNames from 'classnames';
import { SlideProps } from 'entities/Guide';

const DeviceSlide: React.FC<SlideProps> = ({ className, number }) => {
  const { t } = useTranslation();

  const { device, setDevice, next } = useSliderContext();

  return (
    <GuideSlide
      className={classNames(className, styles.slide, styles[`slide-${device}`])}
      head={
        <Flex direction="column" spacing={16}>
          <Flex spacing={20}>
            <Flex className={styles.number} align="center" justify="center">
              <Typography.Text size={6}>{number}</Typography.Text>
            </Flex>
            <Typography.Title level={2}>{t('device.select')}</Typography.Title>
          </Flex>
          <Typography.Text
            className={styles.description}
            type="secondary"
            size={4}
          >
            {t('device.selectDeviceToProtect')}:
          </Typography.Text>
        </Flex>
      }
      body={<DeviceSelector value={device} onChange={setDevice} />}
      controls={
        <Button
          onClick={next}
          className={styles.next}
          type="primary"
          suffix={<Arrow className={styles.arrow} />}
        >
          {t('shared.text.continue')}
        </Button>
      }
    />
  );
};

export { DeviceSlide };
