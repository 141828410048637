import { AuthenticationContainer } from 'entities/Authentication';
import { useTranslation } from 'react-i18next';
import { Flex, Typography } from 'shared/_ui';
import { SuccessIcon } from './SuccessIcon';
import styles from './styles.module.scss';

export const SuccessfulPaymentPage = () => {
  const { t } = useTranslation();

  return (
    <AuthenticationContainer>
      <Flex className={styles.wrapper} direction="column" align="center">
        <SuccessIcon className={styles.icon} />
        <Typography.Title className={styles.heading} level={1}>
          {t('banner.successfulPayment.heading')}
        </Typography.Title>
      </Flex>
    </AuthenticationContainer>
  );
};
