import React from 'react';
import { GuideStep } from 'entities/Guide';
import { useTranslation } from 'react-i18next';
import { Flex } from 'shared/_ui';

export const NotNow: React.FC = () => {
    const { t } = useTranslation();
  
    return (
      <Flex direction="column">
        <GuideStep
          number={1}
          image={''}
          title={t('guide.linux.notnow.title')}
          description={
            <ol>
              <li>{t('guide.linux.notnow.description')}</li>
            </ol>
          }
          final={false}
        />
      </Flex>
    );
  };