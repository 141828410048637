import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notify } from 'shared/lib/notification';
import { getRouteOtpCheck } from 'shared/consts/routes';
import { CreateInviteValues } from '../../api/createInvite';
import { validationScheme } from '../../lib/validationScheme';
import { Button, Checkbox, Flex, MaterialInput, Typography } from 'shared/_ui';
import { useLocalStorage } from 'usehooks-ts';

import styles from './styles.module.scss';
import { useSendOtp } from 'shared/api';
import i18next from 'i18next';

export const CreateInvite: React.FC = () => {
  // eslint-disable-next-line
  const [_, setEmail] = useLocalStorage<string | null>('user-email', null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [otplogin, { isLoading }] = useSendOtp();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateInviteValues>({
    mode: 'onSubmit',
    defaultValues: { email: '' },
    resolver: yupResolver(validationScheme),
  });
  const [isAgree, setIsAgree] = React.useState<boolean>(true);

  const onSubmit = async (data: CreateInviteValues) => {
    if(!isAgree) {
      notify.error(t('authentication.notify.onDisagreeance'));
      return;
    }
    localStorage.setItem('user-email',data.email)
    await otplogin({email: data.email, languageCode: i18next.language})
      .unwrap()
      .then(() => navigate(getRouteOtpCheck()))
      .catch((error) => {
        if (error?.status === 400) {
          notify.error(t('authentication.notify.invitationAlreadySent'));
        } else {
          notify.error(t('shared.error.base'));
        }
      });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" spacing={16} className={styles.wrapper}>
        <div className={styles.field}>
          <MaterialInput
            type="email"
            placeholder={t('authentication.registration.email')}
            {...register('email')}
          />
          {errors.email && (
            <Typography.Text type="danger">
              {t(
                errors.email?.message
                  ? errors.email?.message
                  : 'shared.error.base'
              )}
            </Typography.Text>
          )}
        </div>
        <Checkbox
          className={styles.checkbox}
          checked={isAgree}
          onChange={(e) => {
            setIsAgree(e.target.checked);
          }}
        >
          <div className={styles.agreement}>
            <Typography.Text type="secondary">
              {t('authentication.registration.agreement')}
              &nbsp;
              <Typography.Link href={t('links.offer')} target="_blank">
                {t('authentication.registration.offerAgreement')}
              </Typography.Link>
              &nbsp;
              {t('authentication.registration.and')}
              &nbsp;
              <Typography.Link href={t('links.privacyPolicy')} target="_blank">
                {t('authentication.registration.privacyPolicy')}
              </Typography.Link>
            </Typography.Text>
          </div>
        </Checkbox>
        <Button
          loading={isLoading}
          className={styles.button}
          type="primary"
        >
          {t('authentication.registration.next')}
        </Button>
      </Flex>
    </form>
  );
};
